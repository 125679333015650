@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* Carrusel */
.carouselSection {
  padding: 10px; /* Reducir el espacio en dispositivos más pequeños */
  background-color: #f9f9f9;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  overflow: hidden; /* Evita el desbordamiento */
}

.cardWrapper {
  padding: 0 5px; /* Ajustar el espaciado entre las tarjetas */
  display: flex;
  justify-content: center;
  width: 100%; /* Asegúrate de que el ancho sea adecuado */
  overflow: hidden; /* Evita el desbordamiento */
}

.slick-slide {
  margin: 0 5px; /* Ajustar el margen entre las tarjetas */
}

.slick-list {
  margin: 0 -5px; /* Ajustar el margen negativo */
}

/* Mapa */
.mapSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  max-width: 1300px; /* Establece un máximo ancho para el contenedor */
  height: 530px;
  margin: 0 auto; /* Centra el contenedor en la página */
  border: 2px solid #ccc; /* Añade un borde al contenedor */
  border-radius: 10px; /* Añade bordes redondeados al contenedor */
  overflow: hidden; /* Oculta el contenido desbordante */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-iframe {
  width: 100%;
  height: 100%;
  border: none; /* Elimina el borde del iframe */
  background-size: contain;
}

.mapSection h2 {
  font-size: 1.5em; /* Ajustar el tamaño del título en dispositivos más pequeños */
  margin-bottom: 10px; /* Ajustar el margen inferior del título */
}

/* Header */
.headerContainer {
  position: relative;
  background: url('/public/fondo-home.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Filtro oscuro */
  z-index: 1;
}

.headerContent {
  z-index: 3; /* Asegúrate de que el contenido del header esté por encima del overlay y la Toolbar */
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  overflow: hidden; /* Evita el desbordamiento */
}

.headerContent h1 {
  font-family: "Poppins", sans-serif;
  font-size: 65px; /* Ajusta el tamaño del texto */
  margin: 0;
  font-weight: bold;
}

.headerContent h2 {
  font-size: 32px; /* Ajusta el tamaño del texto */
  margin: 0;
}

.headerContent p {
  font-size: 25px; /* Ajusta el tamaño del texto */
  margin: 5px 0;
}

.headerButtons {
  width: 665px;
  height: 252px;
  position: absolute;
  bottom: -90px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.789);
  padding: 20px;
  border-radius: 10px;
  color: black;
  z-index: 3; /* Mantener el z-index alto para estar por encima del overlay */
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden; /* Evita el desbordamiento */
}

.headerButtons h3 {
  font-size: 36px;
  color: #0056b3;
  font-weight: 700;
}

.headerButtons p {
  font-size: 25px;
  color: #000;
  font-weight: lighter;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 30px;
}

.contactButton,
.projectsButton {
  width: 228px;
  height: 45px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contactButton {
  background-color: #007bff;
  color: white;
}

.projectsButton {
  background-color: #ff8800;
  color: white;
}

.highlight {
  font-size: 32px; /* Ajusta el tamaño del texto */
  color: #00ff00;
}

/* Estilos para la sección "¿Quiénes Somos?" */
.aboutSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px; /* Ajustar el espacio en dispositivos más pequeños */
  background-color: #f9f9f9;
}

.aboutContent {
  flex: 1;
  padding: 20px;
  text-align: justify; /* Justificar el texto */
}

.aboutContent h2 {
  font-size: 36px;
  color: #0056b3;
  font-weight: 700; /* Ajustar el tamaño del título */
  margin-bottom: 20px;
}

.aboutContent p {
  font-size: 1em; /* Ajustar el tamaño del texto */
  color: #333;
  line-height: 1.6;
}

.aboutImage {
  flex: 1; /* Hacer que la imagen ocupe todo el espacio restante */
  padding: 20px;
}

.aboutImage img {
  width: 100%; /* Ajustar el ancho de la imagen al 100% */
  border-radius: 10px;
}

/* Estilos para la sección "Logos de Lugares" */
.logosSection {
  padding: 20px; /* Ajustar el espacio en dispositivos más pequeños */
  background-color: #0056b3;
  color: white;
  text-align: center;
}

.logosSection h2 {
  font-size: 1.5em; /* Ajustar el tamaño del título en dispositivos más pequeños */
}

/* Estilo para el encabezado de secciones */
.sectionHeader {
  position: relative;
  text-align: center;
  margin: 50px 0;
  max-width: 80%; /* Ajusta el ancho máximo de la sección */
  margin-left: auto; /* Centra la sección horizontalmente */
  margin-right: auto; /* Centra la sección horizontalmente */
}

.sectionHeader h2 {
  font-size: 2em;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  padding: 0 20px;
  background-color: #fff; /* Fondo blanco para cubrir la línea */
  position: relative;
  z-index: 1;
}

.sectionHeader::before {
  content: "";
  display: block;
  width: 80%; /* Ajusta el ancho de la línea */
  height: 2px;
  background-color: blue; /* Color de la línea */
  position: absolute;
  top: 50%;
  left: 10%; /* Ajusta la posición de la línea para centrarla */
  z-index: 0;
}

.cardsHome {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Toolbar */
.containerIdioma {
  width: 100%;
  z-index: 4; /* Posicionar por encima del overlay pero por debajo del headerContent */
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}

.containerIdioma1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navBa {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1280px) {

  .carouselSection {
    width: 100%;
    padding: 20px; /* Ajusta el espaciado si es necesario */
  }

  .cardWrapper {
    padding: 0 10px; /* Ajusta el espaciado entre las tarjetas */
  }

  .slick-slide {
    margin: 0 5px; /* Ajustar el margen entre las tarjetas */
  }

  .slick-list {
    margin: 0 -5px; /* Ajustar el margen negativo */
  }
  
  .mapSection {
    width: 90%; /* Establece un máximo ancho para el contenedor */
    height: auto;
    margin: 0 auto; /* Centra el contenedor en la página */
    border: 2px solid #ccc; /* Añade un borde al contenedor */
    border-radius: 10px; /* Añade bordes redondeados al contenedor */
    overflow: hidden; /* Oculta el contenido desbordante */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .map-iframe {
    width: 100%;
    height: 100%;
    border: none; /* Elimina el borde del iframe */
    background-size: contain;
  }

  .headerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    text-align: center;
  }

  .headerContent h2 {
    text-align: center;
    font-size: 1.5em;
  }
  .headerContent h1{
    font-size: 3em;
  }
  .headerContent p{
    font-size: 1.5em;
  }

  .headerButtons{
    display: none;
  }

  .cardsHome{
    width: 100%;
  }

  .carouselSection{
    width: 100%;
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .cardWrapper {
    padding: 0 50px; /* Ajustar el espaciado entre las tarjetas */
    display: flex;
    justify-content: center;
    width: 100%; /* Asegúrate de que el ancho sea adecuado */
  }

}

@media (max-width: 768px) {

  .carouselSection {
    width: 100%;
    padding: 20px; /* Ajusta el espaciado si es necesario */
  }

  .cardWrapper {
    padding: 0 10px; /* Ajusta el espaciado entre las tarjetas */
  }

  .slick-slide {
    margin: 0 5px; /* Ajustar el margen entre las tarjetas */
  }

  .slick-list {
    margin: 0 -5px; /* Ajustar el margen negativo */
  }
  
  .mapSection {
    width: 90%; /* Establece un máximo ancho para el contenedor */
    height: auto;
    margin: 0 auto; /* Centra el contenedor en la página */
    border: 2px solid #ccc; /* Añade un borde al contenedor */
    border-radius: 10px; /* Añade bordes redondeados al contenedor */
    overflow: hidden; /* Oculta el contenido desbordante */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .map-iframe {
    width: 100%;
    height: 100%;
    border: none; /* Elimina el borde del iframe */
    background-size: contain;
  }

  .headerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .headerContent h2 {
    text-align: center;
    font-size: 1.5em;
  }
  .headerContent h1{
    font-size: 3em;
  }
  .headerContent p{
    font-size: 1.5em;
  }

  .headerButtons{
    display: none;
  }

  .cardsHome{
    width: 100%;
  }

  .carouselSection{
    width: 100%;
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 40px;
  }


  .cardWrapper {
    padding: 0 50px; /* Ajustar el espaciado entre las tarjetas */
    display: flex;
    justify-content: center;
    width: 100%; /* Asegúrate de que el ancho sea adecuado */
  }

}

@media (max-width: 480px) {
  .mapSection {
    width: 90%; /* Establece un máximo ancho para el contenedor */
    height: auto;
    margin: 0 auto; /* Centra el contenedor en la página */
    border: 2px solid #ccc; /* Añade un borde al contenedor */
    border-radius: 10px; /* Añade bordes redondeados al contenedor */
    overflow: hidden; /* Oculta el contenido desbordante */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .map-iframe {
    width: 100%;
    height: 100%;
    border: none; /* Elimina el borde del iframe */
    background-size: contain;
  }

  .aboutContent {
    text-align: justify; /* Justificar el texto en dispositivos móviles */
  }

  .aboutContent h2 {
    font-size: 1.2em; /* Reducir el tamaño del título en dispositivos móviles */
  }

  .aboutContent p {
    font-size: 0.9em; /* Reducir el tamaño del texto en dispositivos móviles */
  }

  .aboutImage {
    display: none; /* Ocultar la imagen en dispositivos móviles */
  }

  .headerContent h2 {
    font-size: 0.8em;
  }
  .headerContent h1{
    text-align: center;
    font-size: 1.2em;
  }
  .headerContent p{
    font-size: 0.6em;
  }

  .headerButtons{
    width: 90%;
  }
  
}