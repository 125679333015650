.card {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  max-height: 550px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  background-color: #1a1a1a;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  position: relative; /* Necesario para el posicionamiento absoluto de cardContent */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardImage {
  width: 100%;
  height: 550px; /* Altura fija para todas las imágenes */
  border-radius: 10px;
  border: 1px solid #282828;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  position: relative; /* Necesario para z-index */
  z-index: 0;
}

.cardImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cardContent {
  background-color: rgba(0, 0, 0, 0.627);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 10px;
  text-align: left;
  position: absolute; /* Para estar en la parte inferior de la tarjeta */
  bottom: 0;
  opacity: 0; /* Oculto por defecto */
  transition: opacity 0.3s ease; /* Transición suave para la opacidad */
  z-index: 1;
}

.card:hover .cardContent {
  opacity: 1; /* Mostrar al hacer hover en card */
}

.cardHeader h4 {
  margin: 0;
  font-size: 0.8em;
  color: #b3b3b3;
}

.cardHeader p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #ffffff;
  text-align: justify;
}

.cardContent h3 {
  margin: 10px 0 0;
  font-size: 1.4em;
  color: #fff;
}

@media (max-width: 480px) {
  .card{
    width: 90%;
    height: 100%;
    display: flex;
  }

  .cardImage{
    width: 100%;
    min-height: 400px;
    height: auto;
  }

  .cardImage img {
    object-fit: cover;
  }

  .cardHeader p {
    display: none;
  }

  .cardHeader h4{
    margin: 20px;
  }

  .cardHeader h3{
    font-size: 0.1em;
  }

}
