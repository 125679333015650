.card {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
    min-height: 450px;
    height: auto;
    /*background: #f4f4f4;*/
    padding: 1rem;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
}
  
.fixedSizeImage {
    width: 100%;
    height: 350px; /* Altura fija para todas las imágenes */
    border-radius: 10px;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.fixedSizeImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card h3 {
    display: flex;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
  
.card p {
    display: flex;
    font-size: 1rem;
    margin-bottom: 1rem;
}
  
.card button {
    background-color: #0800ff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
    width: 40%;
}

.containerBot{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerBot p{
    font-size: 1rem;
    margin-bottom: 0rem;
}

.btnCard{
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 15px;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    max-width: 1000px;
    width: 90%;
  }

  .modalContent1 {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    max-width: 600px;
    width: 90%;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .modalContent h2{
    margin-bottom: 30px;
  }

  .contentI{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
  
  .contentI label{
    padding: 0;
    margin-bottom: 5px;
  }

  .formControl {
    width: 100%;
    padding: 0.7rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0;
  }
  
  .formControlI {
    width: 100%;
    padding: 0.7rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0;
    background-color: rgb(243, 243, 255);
  }

  .btn {
    position: relative;
    border-radius: 10px;
    border: 1px solid #0800ff;
    background-color: #0800ff;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 50px;
    letter-spacing: 1px;
    text-decoration: none; 
    width: 100%;
    margin-top: 10px;
  }
  
  .btn:hover {
    background-color: #0600c1;
  }

  .eliminacionM{
    display: flex;
    flex-direction: column;
  }

  .eliminacionMBtn{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .btn1 {
    position: relative;
    border-radius: 10px;
    border: 1px solid #0800ff;
    background-color: #0800ff;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 50px;
    letter-spacing: 1px;
    text-decoration: none; 
    width:  40%;
    margin-top: 10px;
  }
  
  .btn:hover1 {
    background-color: #0600c1;
  }

  .no-scroll {
    overflow: hidden;
  }

  .modalContent {
    position: relative;
    max-width: 600px; /* Ajusta según el tamaño que desees */
    max-height: 80vh; /* Ajusta la altura máxima */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
    padding: 20px; /* Añade un poco de relleno si lo necesitas */
    background-color: #fff; /* Asegúrate de que el fondo sea blanco para la visibilidad */
    border-radius: 8px; /* Opcional, para bordes redondeados */
  }
  
  .modalOverlay {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente para el overlay */
  }


/* Añade este estilo para las imágenes adicionales */
.additionalImageWrapper {
  margin: 10px;
  display: inline-block;
}

.additionalImage {
  width: 100px; /* Ajusta el tamaño según tu necesidad */
  height: 100px; /* Ajusta el tamaño según tu necesidad */
  object-fit: cover; /* Mantiene la relación de aspecto */
}

.additionalImagesContainer {
  display: flex;
  flex-wrap: wrap; /* Alinea las imágenes en varias filas si es necesario */
  gap: 10px; /* Espacio entre las imágenes */
}

@media (max-width: 480px) {
  .card{
    width: 90%;
    min-height: 450px;
    height: auto;
  }

  .card h3 {
    display: flex;
    align-items: start;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

}
