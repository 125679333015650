
input[type="checkbox"] {
    appearance: auto; /* Restaura el estilo predeterminado del navegador */
  }

  .registerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/overlay.jpg');
    background-size: cover;
}

.titulo {
    margin-bottom: 30px;
}

.titulo h2 {
    font-weight: 700;
}

.registerBox {
    max-height: 650px;
    height: auto;
    width: 400px;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.inputField {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.aceptoCondiciones {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    z-index: 1;
}

.aceptoCondiciones label {
    margin-left: 5px;
    font-size: 0.8em;
}

.registerButton {
    position: relative;
    border-radius: 15px;
    border: 1px solid #4bb6b7;
    background-color: #4bb6b7;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    margin: 5px;
    padding: 20px 80px;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    margin-top: 40px;
    margin-bottom: 30px;
}

.registerButton:hover {
    letter-spacing: 3px;
}

.registerButton:active {
    transform: scale(0.95);
}

.registerButton:focus {
    outline: none;
}

.registerButton.ghost {
    border: 2px solid #fff;
    color: #fff;
}

.registerButton.ghost i {
    position: absolute;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.footerR {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footerR a {
    font-size: small;
    color: #ababab;
}

.footerR a:hover {
    color: cornflowerblue;
}

.termsButton {
    margin-top: 10px;
    background: none;
    border: none;
    font-size: small;
    color: #ababab;
}

@media (max-width: 768px) {
    .registerBox {
        width: 90%;
    }
}

.error {
    color: red;
    font-size: 0.8rem;
}
