.filterBar {
    display: flex;
    justify-content: space-around;
    background-color: #0033cc;
    padding: 20px;
    margin: 20px auto;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .filterSelect {
    width: 300px;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .filterSelect:hover {
    border-color: #aaa;
  }
  
  .filterSelect:focus {
    outline: none;
    border-color: #0056b3;
    box-shadow: 0 0 8px rgba(0, 86, 179, 0.5);
  }

  .filterButton {
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    border: none;
    background-color: #ff0000;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .filterButton:hover {
    background-color: #cc0000;
  }

@media (max-width: 850px) {
  .filterSelect{
    width: 50%;
    padding: 5px;
    margin: 5px 5px;
    font-size: 0.6em;
  }

  .filterSelect option{
    font-size: 0.9em;
  }

  .filterButton {
    width: 50%;
    padding: 5px;
    margin: 5px 5px;
    font-size: 0.6em;
  }
}