.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/overlay.jpg');
    background-size: cover;
}

.titulo{
    margin-bottom: 50px;
}

.titulo h2{
    font-weight: 700;
}
  
.loginBox {
    height: auto;
    width: 400px;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}
  
.inputField {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.loginButton {
    position: relative;
    border-radius: 15px;
    border: 1px solid #4bb6b7;
    background-color: #4bb6b7;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    margin: 5px;
    padding: 20px 80px;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    margin-top: 60px;
    margin-bottom: 25px;
}

.footerLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.footerLinks button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 5px;
}

.loginButton:hover {
    letter-spacing: 3px;
}

.loginButton:active {
    transform: scale(0.95);
}

.loginButton:focus {
    outline: none;
}

.loginButton.ghost {
    border: 2px solid #fff;
    color: #fff;
}

.loginButton.ghost i {
    position: absolute;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

  
.footerLinks a {
    text-decoration: none;
    color: inherit; /* Color del texto heredado del padre */
    font-weight: inherit; /* Peso de la fuente heredado del padre */
    font-size: inherit;
}

.footerLinks button:hover {
    background-color: #b3b3b3;
    box-shadow: 0 4px 8px rgba(175, 175, 175, 0.1);
}

.googleLoginContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.googleLoginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
  
.googleLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px; /* Ajusta el tamaño según sea necesario */
    height: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modalBox {
    width: 400px;
    height: 250px;
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalCloseButton {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 370px;
    margin-bottom: 10px;
}

.modalCloseButton:hover{
    background-color: red;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent h1{
    font-size: 1.5em;
    font-weight: 700;
}

.modalContent input{
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modalContent button{
    border-radius: 15px;
    border: 1px solid #0833A2;
    background-color: #0833A2;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    padding: 10px 100px;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
}

.modalContent button:hover{
    background-color: #053cc7;
}

@media (max-width: 768px) {
    .loginBox{
        width: 90%;
    }
    .modalBox{
        width: 90%;
    }

}

.error {
    /* Estilos del mensaje de error */
    color: red;
    font-size: 0.8rem; /* Tamaño de fuente más pequeño */
  }