@import url("https://fonts.googleapis.com/css2?family=Poppins");

/*header*/
.headerContainer {
    position: relative;
    background: url('/public/Nosotros.jpeg') no-repeat center center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Filtro oscuro */
}

.headerContent {
    z-index: 1;
    padding: 20px;
}

.headerContent h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to top, #ffffffc2, #838ffde2);
    -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
    -moz-background-clip: text; /* Para navegadores basados en Gecko (Firefox) */
    background-clip: text; /* Propiedad estándar para navegadores modernos */
    -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
    -moz-text-fill-color: transparent; /* Para navegadores basados en Gecko */
    font-size: 3em;
    text-align: center;
    width: 100%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
    font-size: 100px;
    font-weight: bold;
}

.header-content p {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    text-align: justify;
    text-align-last: center;
    width: 100%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
}

.header-content img {
    max-width: 100%;
    height: auto;
    display: block;
    filter: brightness(60%); /* Reduce el brillo para oscurecer la imagen */
}

.banner {
    position: relative;
    text-align: center;
    margin: 50px 0;
    max-width: 80%; /* Ajusta el ancho máximo de la sección */
    margin-left: auto; /* Centra la sección horizontalmente */
    margin-right: auto; /* Centra la sección horizontalmente */
}

.banner h3 {
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    background-color: #fff; /* Fondo blanco para cubrir la línea */
    position: relative;
    z-index: 1;
}

.banner::before {
    content: "";
    display: block;
    width: 80%; /* Ajusta el ancho de la línea */
    height: 2px;
    background-color: blue; /* Color de la línea */
    position: absolute;
    top: 50%;
    left: 10%; /* Ajusta la posición de la línea para centrarla */
    z-index: 0;
}

.about {
    font-weight: lighter;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    text-align-last: center;
}

.about-container1 {
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 12px; /* Margin para tener una separación de 24px entre contenedores */
    text-align: justify;
    text-align-last: center;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
}

.containerUno{
    width: 95%;
    display: flex;
    flex-direction: row;
}

.bloques{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-content: center;
}

.tresAbajo{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.about-container { 
    width: 400px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin: 20px 12px; /* Margin para tener una separación de 24px entre contenedores */
    text-align: justify;
    text-align-last: center;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
}

.misionPP {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /*background-color: rgba(215, 221, 250, 0.537);*/
}

.about h4 {
    text-align: justify;
    text-align-last: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

.about p {
    font-weight: lighter;
    text-align: justify;
    text-align-last: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres columnas de igual ancho */
    grid-template-rows: repeat(2, 1fr); /* Dos filas de igual altura */
    gap: 10px; /* Espacio entre las celdas */
    align-items: center;
}

.grid-container img {
    width: 50%;
    height: auto;
    overflow: hidden; /* Oculta cualquier desbordamiento de las imágenes */
    display: block;
    margin: auto;
}

.navBa{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

/* Media queries para hacer el diseño responsive */
@media (max-width: 850px) {
    .headerContent h1 {
        font-size: 4em;
    }

    .headerContent p {
        font-size: 1.2em;
    }

    .grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 1fr);
    }

    .containerUno {
        flex-direction: column;
        font-size: 1rem;
        padding-top: 0%;
        margin-left: 0%;
        padding-left: 0%;
    }

    .about p {
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .grid-container {
        margin-top: 25px;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Tres columnas de igual ancho */
        grid-template-rows: repeat(2, 1fr); /* Dos filas de igual altura */
    }

    .about-container1,
    .about-container {
        width: 80%; /* Ancho completo en pantallas pequeñas */
        font-size: 0.8em; /* Tamaño de fuente más pequeño */
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .headerContent h1 {
        font-size: 2.5em;
    }

    .headerContent p {
        font-size: .95em;
        margin-top: 1em;
    }

    .banner::before,
    .banner::after {
        width: calc(50% - 120px);
    }

    .banner h3 {
        font-size: 1.2em;
    }

    .containerUno {
        flex-direction: column;
        font-size: 1rem;
        padding-top: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bloques{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    .tresAbajo{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    .about h4 {
        padding-bottom: 2%;
    }

    .about p {
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .grid-container {
        margin-top: 25px;
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Tres columnas de igual ancho */
        grid-template-rows: repeat(2, 1fr); /* Dos filas de igual altura */
    }

    .grid-container img {
        width: 80%;
    }

    .about-container1,
    .about-container {
        width: 80%; /* Ancho completo en pantallas pequeñas */
        font-size: 0.8em; /* Tamaño de fuente más pequeño */
        display: block;
    }
}
