/* Header */
.headerContainer {
  position: relative;
  background: url('/public/inm2.JPG') no-repeat center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Filtro oscuro */
}

.headerContent {
  z-index: 1;
  padding: 20px;
}

.headerContent h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to right, #ffffffc2, #838ffde2);
          -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
          -moz-background-clip: text; /* Para navegadores basados en Gecko (Firefox) */
          background-clip: text; /* Propiedad estándar para navegadores modernos */
          -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
          -moz-text-fill-color: transparent; /* Para navegadores basados en Gecko */
  font-size: 3em;
  text-align: center;
  width: 100%;
  z-index: 1; /* Asegura que el texto esté delante de la imagen */
  font-size: 100px; 
  font-weight: bold;
}

/* Planes */
.plancontainer {
  padding: 60px;
  background-color: #f4f4f4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.planheader {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 20px;
  padding: 30px 20px; /* Reducido el espacio lateral */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(85, 85, 85, 0.158);
}

.planheader h2 {
  color: #000000;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
}

.plans {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
}

.plan {
  background-color: #2c2c2c;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 548px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.plan h3 {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 30px;
}

.planprice {
  font-size: 1.5em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.plan ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: start;
  flex-direction: column;
}

.plan ul li {
  margin-top: 15px;
  margin-bottom: 10px;
}

.paymentButton{
  color: white;
}

.navBa{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* Media Queries */

@media (max-width: 1200px) {
  .plancontainer {
    padding: 30px;
  }
}

@media (max-width: 1000px) {
  .headerContent h1 {
    font-size: 2.5em;
  }

  .plancontainer {
    padding: 20px;
  }

  .plan h3 {
    font-size: 1.5em;
  }

  .plans{
    flex-direction: row;
    display: flex;
    align-items: start;
    justify-content: center;
    
  }
  .plan {
    padding: 20px;
    max-width: 100%;
  }

  .plan ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: self-start;
    flex-direction: column;
  }
  
  .plan ul li {
    margin-top: 15px;
    margin-bottom: 10px;
  }

}

@media (max-width: 768px) {
  .headerContent h1 {
    font-size: 4em;
  }

  .plancontainer {
    padding: 20px;
  }

  .plan h3 {
    font-size: 1.5em;
  }

  .plan {
    padding: 20px;
    max-width: 100%;
  }

  .plan ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: self-start;
    flex-direction: column;
  }
  
  .plan ul li {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .headerContent h1 {
    font-size: 2.5em;
  }

  .plancontainer {
    padding: 20px;
  }

  .planheader h2 {
    font-size: 1.5em;
  }

  .plans{
    flex-direction: column;
  }
  .plan {
    padding: 20px;
    max-width: 100%;
  }

  .plan ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: self-start;
    flex-direction: column;
  }
  
  .plan ul li {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
/*
  .headerContent h1 {
    font-size: 4em;
  }

  .plancontainer {
    padding: 20px;
  }

  .planheader h2 {
    font-size: 1.5em;
  }

  .plan {
    padding: 20px;
    max-width: 100%;
  }

  .plan ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: self-start;
    flex-direction: column;
  }
  
  .plan ul li {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .planbutton {
    width: 100%;
    font-size: 0.8em;
    padding: 8px 16px;
  }

  .planheader {
    width: 100%;
    padding: 20px;
  }*/