/*header*/
.headerContainer {
    position: relative;
    background: url('/public/inm1.JPG') no-repeat center center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    margin-bottom: 35px;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Filtro oscuro */
}
  
  .headerContent {
    z-index: 1;
    padding: 20px;
}

.headerContent h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, #ffffffc2, #838ffde2);
            -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
            -moz-background-clip: text; /* Para navegadores basados en Gecko (Firefox) */
            background-clip: text; /* Propiedad estándar para navegadores modernos */
            -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
            -moz-text-fill-color: transparent; /* Para navegadores basados en Gecko */

    text-align: center;
    width: 80%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
    font-size: 100px; 
    font-weight: bold;
}

.content {
    position: relative;
    height: auto;
}

.content h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4em;
    text-align: center;
    width: 100%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
}

.content img {
    max-width: 100%;
    height: auto;
    display: block;
    filter: brightness(60%); /* Reduce el brillo para oscurecer la imagen */
}
.listings {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tres columnas con igual ancho */
    gap: 20px; /* Espacio entre las tarjetas */
    padding: 0 20px; /* Padding a los lados */
}

.listing {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.listing img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.listing h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.listing p {
    margin-bottom: 5px;
}

.listing a {
    display: block;
    text-align: center;
    padding: 8px 0;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.listing a:hover {
    background-color: #0056b3;
}

.btn1 {
    position: relative;
    border-radius: 10px;
    border: 1px solid #0800ff;
    background-color: #0800ff;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 50px;
    letter-spacing: 1px;
    text-decoration: none; 
    width: 25%;
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 10px;
  }
  
  .btn1:hover {
    background-color: #0600c1;
  }

.addButton {
    position: relative;
    border-radius: 10px;
    border: 1px solid #4bb6b7;
    background-color: #4bb6b7;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    margin: 10px;
    padding: 10px 50px;
    letter-spacing: 1px;
    text-decoration: none; 
}

.addButton:hover {
    background-color: #45a4a5;
}

.navBa{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

/* Media query para pantallas de hasta 850px */
@media (max-width: 850px) {
    .headerContent h1 {
        font-size: 4em;
    }

    .listings {
        grid-template-columns: repeat(2, 1fr); /* Dos columnas en lugar de tres */
    }
}

/* Media query para pantallas de hasta 450px */
@media (max-width: 450px) {
    .headerContent h1 {
        font-size: 1.5em;
    }

    .listings {
        grid-template-columns: repeat(1, 1fr); /* Una sola columna en lugar de dos */
        padding: 0 10px; /* Menor padding a los lados */
    }

    .btn1{
        width: 90%;
    }
}