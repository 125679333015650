/*header*/
.headerContainer {
  position: relative;
  background: url('/public/NTC-vmake.jpeg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  margin-bottom: 35px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Filtro oscuro */
}

.headerContent {
  z-index: 1;
  padding: 20px;
}

.headerContent h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to top, #ffffffc2, #838ffde2);
            -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
            -moz-background-clip: text; /* Para navegadores basados en Gecko (Firefox) */
            background-clip: text; /* Propiedad estándar para navegadores modernos */
            -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
            -moz-text-fill-color: transparent; /* Para navegadores basados en Gecko */
  font-size: 3em;
  text-align: center;
  width: 100%;
  z-index: 1; /* Asegura que el texto esté delante de la imagen */
  font-size: 100px; 
  font-weight: bold;
}

.newsGrid {
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  gap: 4rem;
  justify-content: center;
  margin: 0 auto; /* Centrar la cuadrícula */
  max-width: 1500px; /* Limitar el ancho de la cuadrícula */
  padding-bottom: 4rem;
}

.navBa{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}


/* Responsive Design */
@media (max-width: 1024px) {
  .newsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .newsGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .headerContent h1 {
      font-size: 2.5em;
  }
}
