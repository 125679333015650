.termsContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .termsBox {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }