.containerAI {
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.card {
  width: 100%;
  max-width: 1200px;
  max-height: 680px;
  min-height: 500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  margin-top: 6px;
}

.cardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  box-sizing: border-box;
}

.cardImage {
  width: 50%;
  height: auto;
  border-radius: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img {
  width: fit-content;
  height: fit-content;
  object-fit: cover;
}

.titulo, .subtitulo, .desc {
  margin: 10px 0;
  text-align: justify;
  text-align-last: start;
}

.title {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
}

.subtitle {
  font-size: 2em;
  color: #ff0000;
  margin: 0;
}

.description {
  font-size: 1.2em;
  color: #333;
  text-align: justify;
  text-align-last: start;
}

@media (max-width: 1200px) {
  .card {
    flex-direction: column;
  }

  .cardImage {
    width: 100%;
    height: auto;
  }

  .cardContent {
    width: 100%;
  }

  .title {
    font-size: 2.5em;
  }

  .subtitle {
    font-size: 1.8em;
  }

  .description {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 50px;
  }

  .cardImage {
    width: 100%;
    height: auto;
  }

  .cardContent {
    width: 100%;
    padding: 5px; 
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.5em;
  }

  .description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .card{
    margin-top: 0;
    height: auto;
  }
  
  .cardContent {
    padding: 5px; 
  }

  .title {
    font-size: 1.5em;
  }

  .subtitle {
    font-size: 1.2em;
  }

  .description {
    font-size: 0.8em;
  }
}
