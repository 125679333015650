.container {
  max-width: 1200px;
  max-height: 700px; /* Altura fija del contenedor en pantallas grandes */
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: 20px auto;
}

.content{
  height: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  max-height: 700px; /* Altura máxima de la imagen */
  object-fit: cover;
}

.textContainer {
  width: 100%;
  padding: 0 20px;
  text-align: justify;
}

.textContainer h1 {
  color: #3b5998;
  font-size: 2rem;
  margin-bottom: 20px;
  border-bottom: 2px solid #3b5998;
  /*display: inline-block;*/
}

.textContainer p {
  font-size: 1rem;
  line-height: 1.5;
}

.highlight {
  font-weight: bold;
  color: #3b5998;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: auto; /* Altura automática para ajustar al contenido */
  }
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; /* Altura automática para ajustar al contenido */
  }

  .imageContainer {
    flex: 1;
    max-width: 100%; /* Ocupa la mitad del ancho disponible */
    margin-right: 0; /* Espacio entre la imagen y el texto */
    text-align: center; /* Alineación de la imagen a la izquierda */
  }

  .imageContainer img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .textContainer {
    flex: 1;
    max-width: 100%; /* Ocupa la mitad del ancho disponible */
    padding: 20px;
    text-align: justify;
  }
}
