.footer {
    color: white;
    text-align: center;
}

.footerTop {
    background-color: #0833A2; /* Cambiar el color de fondo del footerTop */
    width: 100%; /* Asegurar que el footerTop abarque todo el ancho */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
    border-bottom: 1px solid #444;
}

.footerLogo {
    text-align: left;
    margin-left: 1em; /* Agregar margen a la izquierda para evitar superposición */
}

.footerLogo img {
    width: 50px;
    height: auto;
}

.footerSocial {
    display: flex;
    justify-content: center;
    margin-right: 1em; /* Agregar margen a la derecha para evitar superposición */
}

.footerSocial a {
    color: white;
    margin: 0 0.5em;
    font-size: 1em;
    transition: color 0.3s;
}

.footerSocial a:hover {
    color: #FFD700; /* Gold */
}

.footerInfo {
    text-align: right;
    margin-right: 1em; /* Agregar margen a la derecha para evitar superposición */
}

.footerInfo h3 {
    margin-bottom: 0.3em;
    font-size: 0.9em;
}

.footerInfo p {
    margin: 0.2em 0;
    font-size: 0.8em;
}

.footerBottom {
    font-size: 0.8em;
    color: #fff; /* Cambiar el color del texto en el footerBottom */
    background-color: #157EFF; /* Cambiar el color de fondo del footerBottom */
    padding: 0.5em;
    text-align: center; /* Centrar el texto en el footerBottom */
}

.footerBottom a {
    color: white;
    text-decoration: none;
    margin: 0 0.3em;
}

.footerBottom a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 450px) {
    .footerLogo {
        margin-left: 0.5em; /* Reducir el margen a la izquierda para pantallas pequeñas */
    }

    .footerSocial {
        margin-right: 0.5em; /* Reducir el margen a la derecha para pantallas pequeñas */
    }

    .footerInfo {
        margin-right: 0.5em; /* Reducir el margen a la derecha para pantallas pequeñas */
    }

    .footerInfo h3 {
        font-size: 0.8em; /* Reducir el tamaño del texto para pantallas pequeñas */
    }

    .footerInfo p {
        font-size: 0.7em; /* Reducir el tamaño del texto para pantallas pequeñas */
    }

    .footerBottom {
        font-size: 0.7em; /* Reducir el tamaño del texto para pantallas pequeñas */
    }

    .footerBottom a {
        margin: 0 0.2em; /* Reducir el margen entre los enlaces para pantallas pequeñas */
    }
}

