/* projects.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    max-width: 90%;
    width: 100%;
    max-height: 90vh; /* Limita la altura del modal al 90% de la altura visible */
    overflow-y: auto; /* Agrega desplazamiento vertical dentro del modal si es necesario */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    margin: auto; /* Centra el modal horizontalmente */
    margin-top: 5%; /* Ajusta el margen superior según sea necesario */
    margin-bottom: 5%; /* Ajusta el margen inferior según sea necesario */
}

.form-group {
    margin-bottom: 1rem;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}
