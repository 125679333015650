.btnP {
    width: 400px;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #002a93;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btnP:hover {
    background-color: #003cd2;
  }


  @media (max-width: 1000px) {
    .btnP {
        width: 100%;
        font-size: 0.8em;
        padding: 8px 16px;
      }
  }

  @media (max-width: 768px) {
    .btnP {
        width: 100%;
        font-size: 0.8em;
        padding: 8px 16px;
      }
  }

  @media (max-width: 480px) {
    .btnP {
        width: 100%;
        font-size: 0.8em;
        padding: 8px 16px;
      }
  }