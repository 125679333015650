.toolbarContainer{
  margin: 0;
  width: 1480px;
  height: 100vh;
  margin-left: 88%;
  display: flex;
  align-items: end;
}

.toolbar {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 5px;
}

.toolbarItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
  
.icon {
  height: 20px;
  margin-right: 5px;
}
  
span {
  font-size: 14px;
  color: white;
}

@media screen and (max-width: 768px) {
  .toolbarContainer{
    width: 100%;
    margin-left: 80%;
  }

  .toolbar{
    width: 100%;
  }

  .toolbarItem{
    width: 50%;
  }
}
  
@media screen and (max-width: 480px) {
  .toolbarContainer{
    width: 100%;
    margin-left: 60%;
  }

  .toolbar{
    width: 100%;
  }

  .toolbarItem{
    width: 50%;
  }
}