/* Base Styles */
.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative; /* Asegura que el menú desplegable se posicione correctamente */
    width: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    top: 0;
    left: 0;
}

.hamburger {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: none; /* Oculto por defecto */
    color: azure;
}

.logo {
    margin-left: 0.5em;
    height: 45px;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    width: 180px;
    filter: brightness(100%); /* Reduce el brillo para oscurecer la imagen */
}

.menu {
    width: 60%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    margin-left: 2em;
}

.ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.ul.open {
    display: flex;
    flex-direction: column;
    max-height: 600px; /* Ajusta según el número de elementos en el menú */
    padding-bottom: 20px;
}

.ul li {
    white-space: nowrap;
}

.ul li a {
    display: inline-block; /* Asegura que los elementos sean bloques en línea */
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 1.25rem; /* 1.25 * 20px = 25px */
    padding: 8px 18px; /* Ajusta el padding para los elementos de la lista */
    transition: all 300ms;
    border-bottom: 1px solid rgba(255, 68, 0, 0);
    width: 100%;
}

.ul li a:hover {
    color: orangered;
    border-bottom: 1px solid orangered;
}

.auth {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.auth a {
    height: 45px;
    display: inline-block; /* Asegura que los elementos sean bloques en línea */
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 1.25rem; /* 1.25 * 20px = 25px */
    padding: 8px 18px; /* Ajusta el padding para los elementos de la lista */
    transition: all 300ms;
    border-bottom: 1px solid rgba(255, 68, 0, 0);
    width: 100%;
}

.auth a:hover {
    color: orangered;
    border-bottom: 1px solid orangered;
}

.joinNow {
    background-color: #00db16;
    border-radius: 0.7em;
    color: black;
    padding: 0.5rem; 
    white-space: nowrap; /* Evita que el texto se ajuste a la siguiente línea */
}

.profileMenu {
    width: 80%;
    display: flex;
    justify-content: end;
    align-items: center;
    white-space: nowrap;
}

.profileImage {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.profileOptions {
    position: absolute; 
    top: 60px; 
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10; 
    width: 200px; 
}

.profileOptions a,
.profileOptions button {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}

.profileOptions a:hover,
.profileOptions button:hover {
    color: red; 
}

.profileOptions a.logged-in {
    color: #000; 
}

.profileOptions a.logged-in:hover {
    color: #333; 
}

.ul li.ocu {
    display: none;
}

.gogo {
    margin: 0;
    padding: 5px;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}

.buttonU{
    height: 45px;
    display: inline-block; /* Asegura que los elementos sean bloques en línea */
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 1.25rem; /* 1.25 * 20px = 25px */
    padding: 8px 18px; /* Ajusta el padding para los elementos de la lista */
    transition: all 300ms;
    border-bottom: 1px solid rgba(255, 68, 0, 0);
    width: 100%;
}

.buttonU:hover {
    color: red; 
}

.login {
    display: none;
}

@media (max-width: 850px) {
    .hamburger {
        display: block; 
    }

    .container {
        width: 90%;
    }

    .logo {
        width: 20%;
    }

    .menu {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 100%;
        background: rgba(0, 0, 0, 0.599);
        grid-column: 1 / -1;
        height: auto;
        width: 90%; 
        padding: 0; 
        margin: 0; 
        overflow: hidden; 
        margin-top: 10px;
    }

    .ul {
        max-height: 0;
        overflow: hidden; 
        transition: max-height 0.3s ease-out; 
        display: flex;
        flex-direction: column;
    }

    .ul.open {
        max-height: 600px; 
        padding-bottom: 20px;
    }

    .ul li {
        width: 90%;
        margin-bottom: 5px;
        text-align: center; 
    }

    .ul li a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem; 
    }

    .ul li.ocu {
        display: block;
    }

    .auth {
        display: none;
    }

    .login {
        font-size: 1rem;
        background-color: #949494b8;
        border-radius: 0.7em;
        color: rgb(255, 255, 255);
        padding: 0.5rem; 
        white-space: nowrap;
        text-align: center;
    }

    .joinNow{
        background-color: #858585b8;
    }

    .buttonU{
        font-size: 1rem;
        background-color: #949494b8;
        border-radius: 0.7em;
        color: rgb(255, 255, 255);
        padding: 0.5rem; 
        white-space: nowrap;
        text-align: center;
    }

}

/* Media queries para dispositivos móviles */
@media (max-width: 450px) {
    .hamburger {
        display: block; 
    }

    .container {
        width: 90%;
    }

    .logo {
        width: 40%;
    }

    .menu {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 100%;
        background: rgba(0, 0, 0, 0.599);
        grid-column: 1 / -1;
        height: auto;
        width: 90%; 
        padding: 0; 
        margin: 0; 
        overflow: hidden; 
        margin-top: 10px;
    }

    .ul {
        max-height: 0;
        overflow: hidden; 
        transition: max-height 0.3s ease-out; 
        display: flex;
        flex-direction: column;
    }

    .ul.open {
        max-height: 600px; 
        padding-bottom: 20px;
    }

    .ul li {
        width: 90%;
        margin-bottom: 5px;
        text-align: center; 
    }

    .ul li a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem; 
    }

    .ul li.ocu {
        display: block;
    }

    .auth {
        display: none;
    }

    .login {
        font-size: 1rem;
        background-color: #949494b8;
        border-radius: 0.7em;
        color: rgb(255, 255, 255);
        padding: 0.5rem; 
        white-space: nowrap;
        text-align: center;
    }

    .joinNow{
        background-color: #858585b8;
    }

    .buttonU{
        font-size: 1rem;
        background-color: #949494b8;
        border-radius: 0.7em;
        color: rgb(255, 255, 255);
        padding: 0.5rem; 
        white-space: nowrap;
        text-align: center;
    }

}
