.aaa {
  position: relative;
  background: linear-gradient(to bottom, #b0b0b0, #ffffff);
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(0, 0, 0);
  text-align: center;
  width: 100%;
}

.container {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 35px;
  box-sizing: border-box; /* Añadido */
}

.modalContent {
  position: absolute;
  top: 53%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  max-width: 500px;
  width: 90%;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.contentI{
  display: block;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: auto;
  padding-bottom: 20px;

}

.contentI p{
  padding: 0;
  margin: 0;
}

.profileHeader {
  margin-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  margin-left: 3%;
  width: 100%; /* Añadido */
  box-sizing: border-box; /* Añadido */
}

.profileImg {
  border-radius: 50%;
  width: 9em;
  height: 9em;
}

.imgHeader {
  padding: 5px;
  margin-left: 3%;
  margin-right: 15px;
}

.infHeader {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.formE {
  width: 100%;
  margin-top: 0;
}

.formE h2 {
  margin-bottom: 20px;
}

.btnHeader {
  width: 50%;
  display: flex;
  margin-left: 16.3%; /* Ajustado */
}

.btn {
  position: relative;
  border-radius: 10px;
  border: 1px solid #0800ff;
  background-color: #0800ff;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  margin: 10px;
  padding: 10px 50px;
  letter-spacing: 1px;
  text-decoration: none; 
}

.btn:hover {
  background-color: #0600c1;
}

.btn1 {
  position: relative;
  border-radius: 10px;
  border: 1px solid #0800ff;
  background-color: #0800ff;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 50px;
  letter-spacing: 1px;
  text-decoration: none; 
  width: 100%;
  margin-top: 10px;
}

.btn1:hover {
  background-color: #0600c1;
}

.btnDelete {
  position: relative;
  border-radius: 10px;
  border: 1px solid #76767672;
  background-color: #76767672;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  margin: 10px;
  padding: 10px 50px;
  letter-spacing: 1px;
  text-decoration: none; 
}

.btnDelete:hover {
  background-color: #c10000;
  border: 1px solid #c10000;
}

.btnPhone {
  display:  none; 
}


.ocu {
  display: none;
}

.formControl {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0;
}

.formControlI {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0;
  background-color: rgb(243, 243, 255);
}

.Prop {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0; 
}

.linea {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(219, 219, 219, 0.853);
  width: 100%; 
  height: 0.12em;
  margin-top: 10px;
}

.textP {
  width: 300px; 
  display: flex;
  margin-top: 10px;
  margin-left: 50px;
}

.abajo {
  width: 100%; 
  margin-left: 30px;
}

.abajoBtn {
  width: 100%;
  display: flex;
  margin-left: 10px; 
}

.navBa{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.subscriptionStatus{
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.btnStatus{
  position: relative;
  border-radius: 10px;
  border: 1px solid #0800ff;
  background-color: #0800ff;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 50px;
  letter-spacing: 1px;
  text-decoration: none; 
}

.btnStatus:hover {
  background-color: #0600c1;
}

.contenedorStatus {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  padding: 5px; 
  border-radius: 5px; 
}

.contenedorStatus p {
  margin: 0; 
  padding: 0;
}

.Status{
  display: flex;
  align-items: start;
  justify-content: center;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: rgb(0, 255, 0);
  border-radius: 50%;
  margin-right: 5px;
}

.indicatorRed {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  margin-right: 5px;
}

@media (max-width: 834px) {
  .aaa {
    min-width: 100%;
    min-height: 100vh;
  }

  .container {
    margin-top: 20px;
    width: 100%;
    height: 100%;
  }

  .profileHeader {
    width: 100%;
  }

  .profileImg {
    width: 100px;
    height: 100px;
  }

  .imgHeader {
    margin: 0;
  }

  .infHeader {
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: start;
  }

  .infHeader h3 {
    font-size: 100%;
  }

  .infHeader p {
    font-size: 80%;
  }

  .btnHeader {
    width: 100%;
    margin: 0;
    display: flex;
    margin-left: 16%;
  }

  .Prop {
    margin: 0;
  }

  .linea {
    width: 100%;
  }

  .textP{
    margin-top: 10px;
    margin-left: 10px;
  }

  .abajo{
    margin-top: 10px;
    margin-left: 0;
  }

  .navBa{
    margin-bottom: 10px;
  }


}


@media (max-width: 450px) {
  .aaa {
    min-width: 100%;
    min-height: 100vh;
  }

  .container {
    margin-top: 20px;
    width: 100%;
    height: 100%;
  }

  .profileHeader {
    width: 100%;
  }

  .profileImg {
    width: 100px;
    height: 100px;
  }

  .imgHeader {
    margin: 0;
  }

  .infHeader {
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: start;
  }

  .infHeader h3 {
    font-size: 100%;
  }

  .infHeader p {
    font-size: 80%;
  }

  .btnHeader {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    width: auto;
    height: auto;
    font-size: 0.6em;    
  }

  .Prop {
    margin: 0;
  }

  .linea {
    width: 100%;
  }

  .textP{
    margin-top: 10px;
    margin-left: 10px;
  }

  .abajo{
    margin-top: 10px;
    margin-left: 0;
  }

  .navBa{
    margin-bottom: 10px;
  }

  .btnStatus{
    width: auto;
    height: auto;
    font-size: 0.6em;   
  }
  
  .btnDelete {
    width: auto;
    height: auto;
    font-size: 0.6em;   
  }
}
