/*header*/
.headerContainer {
    position: relative;
    background: url('/public/Contactanos.jpeg') no-repeat center center;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    margin-bottom: 15px;
}
  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Filtro oscuro */
}
  
.headerContent {
    z-index: 1;
    padding: 20px;

}

.headerContent h1 {
    position: absolute;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to top, #ffffffc2, #838ffde2);
            -webkit-background-clip: text; /* Para navegadores basados en WebKit (Chrome, Safari) */
            -moz-background-clip: text; /* Para navegadores basados en Gecko (Firefox) */
            background-clip: text; /* Propiedad estándar para navegadores modernos */
            -webkit-text-fill-color: transparent; /* Para navegadores basados en WebKit */
            -moz-text-fill-color: transparent; /* Para navegadores basados en Gecko */
    font-size: 3em;
    text-align: center;
    width: 100%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
    font-size: 100px; 
    font-weight: bold;
}

.content {
    position: relative;
    height: auto;
}

.content h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4em;
    text-align: center;
    width: 100%;
    z-index: 1; /* Asegura que el texto esté delante de la imagen */
}

.banner{
    position: relative;
    text-align: center;
    margin: 50px 0;
    max-width: 80%; /* Ajusta el ancho máximo de la sección */
    margin-left: auto; /* Centra la sección horizontalmente */
    margin-right: auto; /* Centra la sección horizontalmente */
}

.banner h3{
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    background-color: #fff; /* Fondo blanco para cubrir la línea */
    position: relative;
    z-index: 1;
}

.banner::before {
    content: "";
    display: block;
    width: 80%; /* Ajusta el ancho de la línea */
    height: 2px;
    background-color: blue; /* Color de la línea */
    position: absolute;
    top: 50%;
    left: 10%; /* Ajusta la posición de la línea para centrarla */
    z-index: 0;
}

.formContainer {
    display: flex;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 3em;
}

.formulario {
    width: 70%; /* Ajusta el ancho del formulario */
    padding: 2em; /* Ajusta el relleno interno */
    border: 1px solid #ccc; /* Opcional: añade un borde para resaltar el formulario */
    border-radius: 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Añade una sombra al formulario */
}

.formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em; /* Espacio entre las filas de inputs */
}

.inputGroup {
    display: flex;
    flex-direction: column;
    margin-right: 1em; /* Espacio horizontal entre grupos de inputs */
}

.inputText {
    padding: 0.7em; /* Aumenta el padding para dar más espacio al texto */
    width: 400px; /* Ajustado para hacer los inputs ocupar todo el ancho disponible */
    max-width: 30em; /* Limita el ancho máximo de los inputs para evitar que sean demasiado anchos */
    margin-top: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    transition: border-color 0.3s;
}

.inputText:focus {
    border-color: #007bff;
}

.textarea {
    padding: 0.7em; /* Aumenta el padding para dar más espacio al texto */
    width: 100%; /* Ajusta el ancho del textarea para ocupar todo el espacio disponible */
    height: 10em; /* Ajustado para hacer el textarea más grande */
    margin-top: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    transition: border-color 0.3s;
    resize: vertical; /* Permite redimensionar verticalmente el textarea */
}

.textarea:focus {
    border-color: #007bff;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    max-width: 15em; /* Limita el ancho máximo del botón */
    margin-top: 1em;
}

.submitButton:hover {
    background-color: #0056b3;
}

.fullWidth {
    width: 100%;
}

.info {
    display: flex;
    gap: 20px; /* Espaciado entre los conjuntos */
    justify-content: space-around;
}

.group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.group label{
    font-weight: bolder;
    margin-bottom: 5px; /* Espaciado entre el label y el span */
}

.group span {
    font-weight: bolder;
    display: flex;
    justify-content: center;
    width: 20em;
    padding: 10px;
    margin-top: 1em;
    margin-bottom: 2em;
    /*background-color: #007bff;*/
    color: rgb(0, 0, 0);
    border-radius: 0.5em;
}

.navBa{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .infExtra {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1050px; /* Ancho máximo de la tabla */
    margin: 0 auto; /* Centra la tabla */
    margin-bottom: 25px;
    flex-direction: column;
    border-radius: 1em;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Añade una sombra al formulario */
  }

.direccion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-weight: bold;
}

.telefonos{
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr ;
}

.telc{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 10px;
    font-weight: bold;
}



@media screen and (max-width: 850px) {
    .headerContent h1 {
        font-size: 4em;
    }
    .banner h3 {
        font-size: 1em; /* Cambiar el tamaño del texto para pantallas más pequeñas */
    }
    .banner::before, .banner::after {
        width: calc(50% - 120px); /* Reducir el ancho de las líneas para pantallas más pequeñas */
    }
    .formulario {
        width: 60%; /* Ajusta el ancho del formulario para pantallas más pequeñas */
    }

    .inputGroup {
        margin-right: 1em; /* Restaura el espacio horizontal entre grupos de inputs */
        margin-bottom: 0; /* Elimina el espacio vertical entre grupos de inputs */
    }

    .formRow {
        flex-direction: column; /* Cambia de nuevo a dirección de fila en pantallas más grandes */
    }

    .inputText {
        margin-top: 10px;
        width: 100%; /* Hace que los inputs ocupen todo el ancho del formulario en pantallas pequeñas */
        max-width: none; /* Elimina el límite de ancho máximo en pantallas pequeñas */
    }
    .textarea {
        margin-top: 10px;
        width: 100%; /* Hace que el textarea ocupe todo el ancho del formulario en pantallas pequeñas */
        max-width: none; /* Elimina el límite de ancho máximo en pantallas pequeñas */
    }
    .info {
        align-items: center;
    }
    .group span {
        width: 100%; /* Ajusta el ancho del span para pantallas pequeñas */
    }

    .infExtra {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%; /* Ancho máximo de la tabla */
        margin: 0 auto; /* Centra la tabla */
        margin-bottom: 25px;
        flex-direction: column;
        border-radius: 1em;
        border: 1px solid #ddd;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Añade una sombra al formulario */
      }

      .direccion{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-align-last: justify;
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        font-weight: bold;
    }
    
    .telefonos{
        width: 100%;
        height: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .telc{
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ddd;
        padding: 10px;
        font-weight: bold;
    }
}

@media screen and (max-width: 450px) {
    .headerContent h1 {
        font-size: 2em;
    }
    .headerContent h2 {
        font-size: 1em;
    }
    .banner h3 {
        font-size: .7em; /* Cambiar el tamaño del texto para pantallas más pequeñas */
    }
    .banner::before, .banner::after {
        width: calc(50% - 100px); /* Reducir el ancho de las líneas para pantallas más pequeñas */
    }
    .formulario {
        width: 90%; /* Ajusta el ancho del formulario para pantallas más pequeñas */
    }
    .inputGroup {
        margin-top: 10px;
        margin-right: 1em; /* Restaura el espacio horizontal entre grupos de inputs */
        margin-bottom: 0; /* Elimina el espacio vertical entre grupos de inputs */
    }

    .formRow {
        flex-direction: column; /* Cambia de nuevo a dirección de fila en pantallas más grandes */
    }

    .inputText {
        margin-top: 10px;
        width: 100%; /* Hace que los inputs ocupen todo el ancho del formulario en pantallas pequeñas */
        max-width: none; /* Elimina el límite de ancho máximo en pantallas pequeñas */
    }

    .textarea {
        margin-top: 10px;
        width: 100%; /* Hace que el textarea ocupe todo el ancho del formulario en pantallas pequeñas */
        max-width: none; /* Elimina el límite de ancho máximo en pantallas pequeñas */
    }
    .info {
        flex-direction: column; /* Cambia a columna en pantallas muy pequeñas */
        align-items: center;
    }
    .group span {
        width: 90%; /* Ajusta el ancho del span para pantallas muy pequeñas */
    }

    .infExtra {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%; /* Ancho máximo de la tabla */
        margin: 0 auto; /* Centra la tabla */
        margin-bottom: 25px;
        flex-direction: column;
        border-radius: 1em;
        border: 1px solid #ddd;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Añade una sombra al formulario */
      }

      .direccion{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-align-last: justify;
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        font-weight: bold;
    }
    
    .telefonos{
        width: 100%;
        height: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .telc{
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ddd;
        padding: 10px;
        font-weight: bold;
    }
}




